import React, { useContext } from "react"
import DndLayout, { CharacterContext } from "../components/dndlayout"
import styled, { createGlobalStyle } from "styled-components"
import { Link } from "gatsby"
import { Query } from "react-apollo"
import { COMBAT_QUERY } from "../queries/queries"
import Counter from "../components/counter"

const GlobalStyle = createGlobalStyle`
  body {
        background: #343940;
        color: #111;
	margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
       }
  html {
    box-sizing: border-box;
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
`

const Grid = styled.div`
  max-width: 930px;
  display: grid;
  grid-template-rows: auto 200px;
  width: 100vw;
  height: 100vh;
  grid-column-gap: 12px;
  color: #aab8c4;
  margin: auto auto;
`
const Namebox = styled.div`
  height: 40px;
  background: #374049;
  border-radius: 15px;
  grid-column: span 4;
  max-width: 930px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 100%;
  justify-content: space-between;
`
const CombatBox = styled.div`
  background: #374049;
  border-radius: 15px;
  grid-column: span 4;
`
const CombatBoxContent = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 1fr;
  > h1 {
    grid-column: span 4;
  }
`
const Main = styled.main`
  display: grid;
  height: 100%;
  justify-content: center;
  grid: 60px repeat(4, 1fr) / repeat(4, 1fr);
  grid-gap: 12px;
`
const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #343940;
  color: white;
  text-align: center;
`
const FooterContent = styled.div`
  max-width: 930px;
  margin: auto;
  display: flex;
  justify-content: space-around;
`
function bonus(stat) {
  return Math.floor((stat - 10) / 2)
}
function skillCompute(bonus, proficiency, proficiency_bonus) {
  return bonus + (proficiency ? 1 : 0) * proficiency_bonus
}

const Page = ({ location }) => {
  return (
    <Grid>
      <GlobalStyle />
      <DndLayout>
        <Query query={COMBAT_QUERY}>
          {({ data, loading, error }) => {
            if (loading) return <p>Loading stats...</p>
            if (error) return <p>Error: ${error.message}</p>
            console.log(data)
            return (
              <>
                <Counter />
                <CombatBox>
                  <CombatBoxContent>
                    <h1>Combat Stats</h1>
                  </CombatBoxContent>
                </CombatBox>
              </>
            )
          }}
        </Query>
      </DndLayout>
    </Grid>
  )
}

export default Page
