import gql from "graphql-tag"

const NAME_QUERY = gql`
  {
    Characters {
      Name
      Player_Name
    }
  }
`
const STATS_QUERY = gql`
  {
    Stats {
      Charisma
      Constitution
      Dexterity
      Intelligence
      Strength
      Wisdom
    }
  }
`
const COMBAT_QUERY = gql`
  {
    Combat_Values {
      Armor_Class
      Hit_Dice_Left
      Hit_Dice_Roll_Max
      Hitpoint_Max
      Hitpoints_Current
      Initiative
      Ki_Left
      Ki_Max
      Speed_High
      Speed_Low
    }
  }
`
const SKILLS_QUERY = gql`
  {
    Skills {
      Survival
      Stealth
      Slight_Of_Hand
      Religion
      Proficiency_Bonus
      Persuasion
      Performance
      Perception
      Nature
      Medicine
      Investigation
      Intimidation
      History
      Insight
      Deception
      Athletics
      Arcana
      Animal_Handling
      Acrobatics
    }
    Stats {
      Charisma
      Constitution
      Dexterity
      Intelligence
      Strength
      Wisdom
    }
  }
`
export { STATS_QUERY, NAME_QUERY, SKILLS_QUERY, COMBAT_QUERY}
